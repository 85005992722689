import { getToken } from 'common/utils/getToken'
import {
    LEAD_GENERATION_API_KEY,
    LEAD_GENERATION_SUBSCRIPTION_KEY,
    LEAD_GENERATION_TOKEN_URL,
    LEAD_GENERATION_URL,
} from 'core'
import { getApiParamsOnePlan } from 'common/components/organisms/Calculator/utils/onePlanConfig'
import { occupations } from 'common/components/design-system/organisms/CalculatorCms/steps/CalculatorStepDropdown/occupations'
export const createCrmLead = async (
    data: any,
    calcType: string,
    quote?: any
) => {
    let crmLead = {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.email,
        mobilePhone: data.phone,
        leadSourceName: '857190005', // ILFS Web
        sourceName: 'Retail',
        source: 'Call back',
        callbackScheduledTime: new Date().toISOString(),
        queuerequest: false,
        createGenesysCallback: false,
        interactionId: '00000000-0000-0000-0000-000000000000',
        interactionchannel: 'teleadvice',
        ...getLeadDataByCalcType(data, calcType, quote),
    }
    const getRequestHeaders = async () => {
        const tokenName = 'IL-WebCallBack'
        return {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${(await getToken(LEAD_GENERATION_TOKEN_URL, tokenName)).token
                    }`,
                'Ocp-Apim-Subscription-Key': LEAD_GENERATION_SUBSCRIPTION_KEY,
                API_KEY: LEAD_GENERATION_API_KEY,
            },
        }
    }

    fetch(LEAD_GENERATION_URL, {
        ...(await getRequestHeaders()),
        method: 'post',
        body: JSON.stringify(crmLead),
    })
}

const getLeadDataByCalcType = (data: any, calcType: string, quote?: any) => {
    switch (calcType) {
        case 'OnePlan': {
            const plans = getApiParamsOnePlan(
                data,
                'comprehensive',
                occupations
            )
            return {
                leadSubSource: '857190022',
                description: 'One Plan Quote',
                areaOfInterest: '971180000',
                topic: '857190001',
                age: Number(data.age1),
                OnePlanQuote: {
                    CoverFor: 'Life1',
                    CoverType: 'Comprehensive',
                    OnePlanComprehensiveAmount: Number(
                        quote?.comprehensiveQuoteData.premInclLevy
                    ),
                    OnePlanEssentialAmount: Number(
                        quote?.essentialQuoteData.premInclLevy
                    ),
                    OnePlanStandardAmount: Number(
                        quote?.standardQuoteData.premInclLevy
                    ),
                    IsMortgage:
                        data.mortgageOrRent !== 'Rent' &&
                        data.mortgageOrRent !== 'Neither',
                    IsRent:
                        data.mortgageOrRent === 'Rent' &&
                        data.mortgageOrRent !== 'Neither',
                    RentAmount: Number(data.rentAmount),
                    MortgageAmount: Number(data.mortgagePayment),
                    NumberOfChildren: Number(data.childNum),
                    YoungestChildAge: Number(data.childAge),
                    Life1BillCoverAmount: Number(plans.billPayAmt1),
                    Life2BillCoverAmount: Number(plans.billPayAmt2),
                    Life1DecreasingLifeCover: Number(plans.lifeCoverAmt1),
                    Life2DecreasingLifeCover: Number(plans.lifeCoverAmt2),
                    Life1SpecifiedIllnessCover: Number(
                        plans.specifiedIllnessAmt1
                    ),
                    Life2SpecifiedIllnessCover: Number(
                        plans.specifiedIllnessAmt2
                    ),
                    Premium: quote.comprehensiveQuoteData.premInclLevy,
                },
            }
        }
        case 'Investment': {
            return {
                leadSubSource: '857190051',
                description: 'Investment Quote',
                areaOfInterest: '971180002',
                topic: '857190002',
            }
        }
        case 'MortgageProtection': {
            return {
                leadSubSource: '857190009',
                description: 'Mortgage protection Quote',
                areaOfInterest: '971180007',
                topic: '857190001',
                age: Number(data.age1),
                MortgageProtection: {
                    SumAssured: data.coverNeeded,
                    Term: Number(data.term),
                    Premium: quote.quoteData.premInclLevy,
                    JointLife: data.coverFor === '2',
                    Life2Age: data.age2 ? Number(data.age2) : undefined,
                    IsLife1Smoking: data.isSmoker === 'Y',
                    IsLife2Smoking: data.isSmoker2
                        ? data.isSmoker2 === 'Y'
                        : undefined,
                },
            }
        }
        case 'Pensions': {
            return {
                age: data.age,
                leadSubSource: '857190013',
                areaOfInterest: '971180001',
                topic: '857190000',
                description: 'Pension Quote',
                PensionQuote: {
                    IsPensionIrishLife: data.IspensionwithIL === 'Y',
                    HasPension: data.currentPension === 'Y',
                    SectorType: getJobSector(data),
                    MonthlyContribution: data.contribution1
                        ? Number(data.contribution1)
                        : Number(data.contribution2),
                    EmployerMonthlyContribution: data.jobContribution
                        ? Number(data.jobContribution)
                        : 0,
                    PensionViaEmployer: data.pensionThroughWork === 'Y',
                    IsEmployed: data.employmentStatus === 'Employed',
                    IsSelfEmployed: data.employmentStatus === 'Self-employed',
                    CurrentPensionValue: Number(data.pensionValue),
                    Life1Salary: Number(data.salary),
                    EligibleForStatePension: data.isStatePension === 'Y',
                    IsPensionThroughEmployer: data.pensionThroughWork === 'Y',
                },
            }
        }
        case 'Term Life Protection': {
            return {
                age: Number(data.age1),
                leadSubSource: '857190036',
                areaOfInterest: '971180008',
                topic: '857190001',
                description: 'Term Life Quote',
            }
        }
        case 'TermLife': {
            return {
                age: Number(data.age),
                leadSubSource: '857190036',
                areaOfInterest: '971180008',
                topic: '857190001',
                description: 'Term Life Quote',
            }
        }
        case 'Life Long Protection': {
            return {
                age: Number(data.age1),
                leadSubSource: '857190036',
                areaOfInterest: '971180009',
                topic: '857190001',
                description: 'Life Long Quote',
            }
        }
        case 'IncomeProtection': {
            return {
                leadSubSource: '857190042',
                description: 'Income Protection Quote',
                areaOfInterest: '971180006',
                topic: '857190001',
            }
        }
        default: {
            return {}
        }
    }
}

const getJobSector = (data: any) => {
    if (
        data.employmentStatus === 'Employed' ||
        data.employmentStatus === 'Self-employed'
    ) {
        return 'Private'
    } else {
        return 'Public'
    }
}
